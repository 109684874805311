<template>
  <main>
    <div class="row mt-5 m-0">
      <div class="col-xl-10 m-auto">
        <div class="row mb-4">
          <h2 class="col-xl-9">Appareils</h2>
          <div class="col-xl-3">
            <b-input-group >
              <template #prepend>
                <b-input-group-text><i class="fa fa-search"></i></b-input-group-text>
              </template>
              <b-form-input type="search" v-model="filtre_name" ></b-form-input>
            </b-input-group>
          </div>
        </div>
        <div class="mb-4" style="border-radius:12px;border: solid 3px #32394e">
          <table class="text-center">
            <thead>
            <tr>
              <th scope="col" >Client</th>
              <th scope="col">Adresse IP</th>
              <th scope="col">Adresse Mac</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="data in devices" :key="data.id">
              <td data-label="CLient">{{data.client.fullname}}</td>
              <td data-label="Adresse IP">{{data.ip_address}}</td>
              <td data-label="Adresse Mac"> {{data.mac_address}}</td>
            </tr>
            </tbody>
          </table>
        </div>
        <infinite-loading @infinite="infiniteHandler" class="text-center">
          <div slot="no-more">Aucune autre donnée trouvée</div>
          <div slot="no-results">Aucune donnée trouvée</div>
        </infinite-loading>
      </div>
    </div>
  </main>
</template>

<script>


export default {
  name: "Devices",
  data(){
    return{
      devices:[],
      filtre_name:'',
      page:1
    }
  },
watch:{
  filtre_name: function(){this.filterSearch()}
},

  methods:{
    infiniteHandler($state) {
      this.$http.get('devices?page=' +this.page).then(({ data }) => {
        if (data.data.length) {
          this.page += 1;
          this.devices.push(...data.data);
          $state.loaded();
        } else {
          $state.complete();
        }
      });
    },
    filterSearch() {
      this.$http
          .post('search/devices',{'fullname':this.filtre_name})
          .then(response => {
            console.log(response.data)
            this.devices = response.data.data;
          })
          .catch(error => {
            console.log(error)
          })
    }
  }
}

</script>

<style scoped  src="@/assets/table.css"></style>